import styled from "styled-components";

export const Main = styled.div`
  width: 85%;
  // overflow: auto;
`;
export const ProfileDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0 5px -2px #888;
  width: 15%;
  // padding-inline-end: 40px;
  // align-items: center;
  @media screen and (max-width: 500px) {
    width: 50%;
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  height:60px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #000;
  // margin-bottom:20px
`;

export const LogoAd = styled.img`
    width: 100px;
    height: 60px;
    border-radius: 100px;
`;

export const Image = styled.img`
  border-radius: 100px;
  width: 40px;
  height: 40px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  padding-left:10px;
`;

export const SubDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding:3px 0;


`;

export const PhnText = styled.div`
  color: #000;
  font-family: "Poppins", sans-serif;
  // font-size: 14px;
  padding: 10px 0px 10px 0px;
  cursor: pointer;
`;

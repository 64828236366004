import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0px 0;
  font-size: 1em;
  font-family: Arial, sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
`;

export const Thead = styled.thead`
  background-color: #f3f3f3;
  color: #000000;
  text-align: left;
  border-bottom: 1px solid #000;
`;

export const Th = styled.th`
  padding: 12px 15px;
`;

export const Tbody = styled.tbody`
  tr {
    border-bottom: 0px solid #dddddd;
  }

  tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  tr:nth-of-type(odd) {
    background-color: #ffff;
  }

  tr:last-of-type {
    border-bottom: 0px;
  }
`;

export const Td = styled.td`
  padding: 12px 15px;

`;
export const Tr = styled.tr`
  border-bottom: 0px solid #dddddd;
`;

export const Title = styled.h3`
  font-family: "Poppins", sans-serif;
  margin-left: 15px;
  margin-right: 50vw;
  color: #000;
`;

export const TopContainer = styled.div`
  height: 60px;
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
`;

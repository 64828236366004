import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get, post, put, remove } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Button, Drawer, Form, Input, Select, DatePicker, Upload, Switch, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { validateMessages } from "../../helpers/validation";
import moment from "moment";

const { Option } = Select;

function Seller() {
  const [sellers, setSellers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingSeller, setEditingSeller] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let token = localStorage.getItem("token");

  const getAllSellers = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/admin/sellers?token=${token}`);
      if (response.data.success) {
        setSellers(response.data.sellers);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllSellers();
  }, []);

  const handleEdit = (seller) => {
    setEditingSeller(seller);
    form.setFieldsValue({
      ...seller,
      dob: seller.dob ? moment(new Date(seller.dob)) : null,
      registrationDate: seller.registrationDate ? moment(new Date(seller.registrationDate)) : null,
    });
    setDrawerVisible(true);
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this seller?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDelete(id),
    });
  };
  

  const handleDelete = async (id) => {
    try {
      const res = await remove(`/admin/seller/${id}?token=${token}`);
      if (res.data.success) {
        setSellers(sellers.filter((seller) => seller._id !== id));
      }
    } catch (error) {
      console.error("There was an error deleting the seller!", error);
    }
  };

  const handleAddSeller = () => {
    setEditingSeller(null);
    form.resetFields();
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === "avatar" && values.avatar) {
          formData.append(key, values.avatar[0].originFileObj);
        } else {
          formData.append(key, values[key]);
        }
      });

      if (editingSeller) {
        // Update seller
        await put(`/admin/seller/${editingSeller._id}?token=${token}`, formData);
        setSellers(
          sellers.map((seller) =>
            seller._id === editingSeller._id ? { ...seller, ...values } : seller
          )
        );
      } else {
        // Add new seller
        const response = await post(`/admin/seller/new?token=${token}`, formData);
        setSellers([...sellers, response.data.seller]);
      }

      setDrawerVisible(false);
    } catch (error) {
      console.error("There was an error saving the seller!", error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = sellers.filter((seller) =>
      seller.first_name.toLowerCase().includes(query) ||
      seller.last_name.toLowerCase().includes(query) ||
      seller.email.toLowerCase().includes(query) ||
      seller.companyName?.toLowerCase().includes(query) || // Include search by company name
      seller.registrationNumber?.toLowerCase().includes(query) // Include search by registration number
    );
    setSellers(filtered);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleToggle = async (checked, seller) => {
    try {
      await put(`/admin/seller/${seller._id}/verify?token=${token}`, {
        adminVerified: checked,
      });
      setSellers(
        sellers.map((s) =>
          s._id === seller._id ? { ...s, adminVerified: checked } : s
        )
      );
    } catch (error) {
      console.error("There was an error updating the verification status!", error);
    }
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Sellers</Title>

        <Input
          placeholder="Search sellers"
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginRight: 10, width: 200 }}
        />

        <Button type="default" onClick={handleAddSeller} style={{ margin: 10 }}>
          Add Seller
        </Button>
      </TopContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Id</Th>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Email</Th>
            <Th>Phone</Th>
            <Th>Company Name</Th> {/* New column for company name */}
            <Th>Registration Number</Th> {/* New column for registration number */}
            <Th>Registration Date</Th> {/* New column for registration date */}
            <Th>Verified</Th> {/* New column for the toggle button */}
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {sellers.map((seller) => (
            <Tr key={seller?._id}>
              <Td>{seller?._id}</Td>
              <Td>{seller?.first_name}</Td>
              <Td>{seller?.last_name}</Td>
              <Td>{seller?.email}</Td>
              <Td>{seller?.phone_number}</Td>
              <Td>{seller?.companyName || "-"}</Td> {/* Display company name or "-" if not available */}
              <Td>{seller?.registrationNumber || "-"}</Td> {/* Display registration number or "-" if not available */}
              <Td>{seller?.registrationDate ? moment(seller.registrationDate).format("YYYY-MM-DD") : "-"}</Td> {/* Display formatted registration date or "-" if not available */}
              <Td>
                <Switch
                  checked={seller?.adminVerified}
                  onChange={(checked) => handleToggle(checked, seller)}
                />
              </Td>
              <Td>
                <EditOutlined
                  onClick={() => handleEdit(seller)}
                  style={{ marginRight: 8, cursor: "pointer" }}
                />
                <DeleteOutlined
                  onClick={() => confirmDelete(seller._id)}
                  style={{ cursor: "pointer" }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Drawer
        title={editingSeller ? "Edit Seller" : "Add Seller"}
        width={720}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              { required: true, message: "Please enter the seller's first name" },
              { max: 30, message: "First name cannot exceed 30 characters" },
              { min: 4, message: "First name should have more than 4 characters" },
            ]}
          >
            <Input placeholder="Please enter the seller's first name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              { required: true, message: "Please enter the seller's last name" },
              { max: 30, message: "Last name cannot exceed 30 characters" },
              { min: 1, message: "Last name should have more than 1 character" },
            ]}
          >
            <Input placeholder="Please enter the seller's last name" />
          </Form.Item>
          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[{ required: true, message: "Please enter the date of birth" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Phone Number"
            rules={[
              { max: 30, message: "Phone number cannot exceed 30 characters" },
              { min: 10, message: "Phone number should have 10 characters" },
            ]}
          >
            <Input placeholder="Please enter the phone number" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter the seller's email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input placeholder="Please enter the seller's email" />
          </Form.Item>
          <Form.Item
            name="gender"
            label="Gender"
            rules={[{ required: true, message: "Please select the gender" }]}
          >
            <Select placeholder="Please select the gender">
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="companyName"
            label="Company Name"
            rules={[{ max: 50, message: "Company name cannot exceed 50 characters" }]}
          >
            <Input placeholder="Please enter the company name" />
          </Form.Item>
          <Form.Item
            name="registrationNumber"
            label="Registration Number"
            rules={[{ max: 30, message: "Registration number cannot exceed 30 characters" }]}
          >
            <Input placeholder="Please enter the registration number" />
          </Form.Item>
          <Form.Item
            name="registrationDate"
            label="Registration Date"
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          {/* <Form.Item
            name="avatar"
            label="Avatar"
            valuePropName="fileList"
            // getValueFromEvent={normFile}
            getValueFromEvent={(e) => {
              // The e.fileList might be undefined, so we need to safely handle it
              return Array.isArray(e) ? e : e && e.fileList ? e.fileList : [];
            }}
          >
            <Upload name="avatar" listType="picture" beforeUpload={() => false}>
              <Button icon={<UploadOutlined />}>Upload Avatar</Button>
            </Upload>
          </Form.Item> */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingSeller ? "Update" : "Add"} Seller
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default Seller;

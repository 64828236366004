import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { get, productImageURL } from "../../helpers/apiConfig"; // Your API helper function
import LineGraph from "../../utils/Data"; // Assuming you have a graph utility
import {
  ChildContainer,
  Container,
  ProdSell,
  SectionImage,
  ProdImg,
  SubTitle,
  Title,
  TopContainer,
  DetailsSection,
  ProductDetail,
  DetailTitle,
  DetailValue,
  VendorSummaryCard, // New styled component for Vendor Summary
} from "./DashboardElement";
import LoadingSpinner from "../../components/LoadingSpinner";

function Dashboard() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [queryParams] = useSearchParams(); // Get query params like `role`
  const loggedUser = JSON.parse(localStorage.getItem("LOGGED_USER"));
  const role = loggedUser?.role;

  const getDashboardData = async () => {
    const role = queryParams.get("role"); // Role passed as query parameter
    try {
      setLoading(true);
      const response = await get(`/admin/dashboard?role=${role}`);
      console.log("dashboard", response?.data);

      setData(response?.data?.data);
    } catch (err) {
      console.error(err);
      setError("Error fetching dashboard data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [queryParams]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ backgroundColor: "#FFF", width: "85vw" }}>
      <TopContainer>
        <Title>
          {data?.role === "admin" ? "Admin Dashboard" : "Vendor Dashboard"}
        </Title>
      </TopContainer>

      {role === "admin" ? (
        <Container>
          <ChildContainer>Total User : {data?.totalUsers}</ChildContainer>
          <ChildContainer>Total Vendors : {data?.totalVendors}</ChildContainer>
          <ChildContainer>
            Total Porducts : {data?.totalProducts}
          </ChildContainer>
          <ChildContainer>Total Orders : {data?.totalOrders}</ChildContainer>
          <ChildContainer>
            Total Order Amount : {data?.totalOrderAmount}
          </ChildContainer>
        </Container>
      ) : (
        <Container>
          <ChildContainer>Total Orders : {data?.totalOrders}</ChildContainer>
          <ChildContainer>New Orders : {data?.newOrders}</ChildContainer>
          <ChildContainer>Total Amount : {data?.totalAmount}</ChildContainer>
          <ChildContainer>Net Amount : {data?.netAmount}</ChildContainer>
          <ChildContainer>Commission Amount : {data?.adminFee}</ChildContainer>
        </Container>
      )}

      <div>
        <ChildContainer style={{ backgroundColor: "#FFF" }}>
          <SubTitle>Sales Overview</SubTitle>
          <LineGraph data={data?.monthlySalesData} />
        </ChildContainer>

        <ChildContainer style={{ backgroundColor: "#FFF" }}>
          <SubTitle>Top Selling Products</SubTitle>
          {data?.topSellingProducts?.map((product, index) => (
            <ProdSell key={index}>
              <SectionImage>
                <ProdImg
                  src={
                    product?.images?.[0]
                      ? productImageURL + product.images[0]
                      : "defaultImageURL"
                  }
                  alt={product?.name || "Product image"}
                />
              </SectionImage>
              <DetailsSection>
                <DetailTitle>{product?.title}</DetailTitle>
                <ProductDetail style={{ textAlign: "left" }}>
                  {product?.description}
                </ProductDetail>
                {/* <DetailValue>{product?.quantity} Sold</DetailValue> */}
              </DetailsSection>
            </ProdSell>
          ))}
        </ChildContainer>
      </div>
    </div>
  );
}

export default Dashboard;

import styled from 'styled-components'

export const TopContainer = styled.div`
  height: 60px;
  width: 85vw;
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
`;

export const Title = styled.h3`
  font-family: "Poppins", sans-serif;
  margin-left: 15px;
  margin-right: 50vw;
  color: #000;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  background-color: #f0f0f0;
`;

export const ChildContainer = styled.div`
  flex: 1;
  margin: 10px;
  padding: 20px;
  background-color: #ffcccb;
  text-align: center;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
`;

export const MidContainer = styled.div`
  padding: 20px
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 40px;
  width: 50%;
  background-color: red;
`;

export const SubTitle = styled.h3`
  font-family: "Poppins", sans-serif;
  text-align: left;
  color: #000;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
`;

export const ProdSell = styled.div`
  display: flex;
  flex-direction: row;
 
`;

export const SectionImage = styled.div`
  flex: 1;
  padding: 10px;
`;

export const ProdImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #ccc;
`;

export const DetailsSection = styled.div`
  flex: 2; 
  padding: 10px;
  display: flex;
  align-items: flex-start;  
  flex-direction: column;
`;

export const ProductDetail = styled.div`
  margin-bottom: 10px;
`;

export const DetailTitle = styled.span`
  font-weight: bold;
  color: #333;
`;

export const DetailValue = styled.span`
  color: #666;
  margin-left: 5px;
`;

export const VendorSummaryCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

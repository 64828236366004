import "./App.css";
import Signin from "./Screens/SignIn/SignIn";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Product from "./Screens/Product/Product";
import LandingPage from "./Screens/LandingPage";
import Dashboard from "./Screens/Dashboard/Dashboard";
import Category from "./Screens/Category/Category";
import Seller from "./Screens/Seller/Seller";
import Subcategory from "./Screens/Subcategory/SubCategory";
import SignUp  from "./Screens/SignUp/SignUp";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<SignUp />} />

          <Route path="/*" element={<LandingPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table, Th, Thead, Tbody, Td, Tr, Title, TopContainer } from "../Product/ProductElement";
import { get } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { notification } from "antd";

function VendorPaymentDetails() {
  const [summary, setSummary] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let token = localStorage.getItem("token");

  useEffect(() => {
    fetchVendorPaymentSummary();
  }, []);

  const fetchVendorPaymentSummary = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/orders/payment-summary/vendor/?token=${token}`);
      if (response.data.success) {
        console.log(response?.data);
        setSummary(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.response);
      notification.error({
        message: "Error",
        description: "There was an error fetching the vendor payment summary.",
        placement: "topRight",
      });
    }
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Order Summary</Title>
      </TopContainer>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {/* <h3>Vendor ID: {summary.vendorId}</h3> */}
          <div style={{marginLeft:15}}>
          <p>Total Orders: {summary.totalOrders}</p>
          <p>Total Amount: {summary.totalAmount?.toString().slice(0,6)}</p>

          <p>Coupon Deduction: {summary.totalDiscount?.toString().slice(0,6)}</p>
          <p>Admin Fee: {summary.adminFee?.toString().slice(0,6)}</p>
          <p>Net Amount: {summary.netAmount?.toString().slice(0,6)}</p>
          <h4>Items List:</h4>
          </div>
          <Table>
            <Thead>
              <Tr>
                <Th>Product ID</Th>
                <Th>Title</Th>
                <Th>Quantity</Th>
                <Th>Order Price</Th>
                <Th>Coupon Deduction</Th>
                <Th>Balance Amount</Th>
                <Th>Tracking Status</Th>
                <Th>Payment Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {summary?.itemsList?.map((item) => (
                <Tr key={item._id}>
                  <Td>{item.productId}</Td>
                  <Td>{item.title}</Td>
                  <Td>{item.quantity}</Td>
                  <Td>{(item.price * item.quantity)?.toString().slice(0,6)}</Td>
                  <Td>{item.discount * item.quantity}</Td>
                  <Td>{((item.price - item.discount) * item.quantity)?.toString().slice(0,6)}</Td>
                  <Td>{item.trackingStatus}</Td>
                  <Td>{item.paymentStatus}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default VendorPaymentDetails;

import React, { useState, useEffect } from "react";
import { Table, Th, Thead, Tbody, Td, Tr, Title, TopContainer } from "../Product/ProductElement";
import { get } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { notification, Button, Modal, List } from "antd";

function PaymentSummary() {
  const [summary, setSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totals, setTotals] = useState({});
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorDetails, setVendorDetails] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let token = localStorage.getItem("token");

  useEffect(() => {
    fetchPaymentSummary();
  }, []);

  const fetchPaymentSummary = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/orders/payment-summary?token=${token}`);
      if (response.data.success) {
        console.log(response);
        setSummary(response.data.summary);
        setTotals({
          totalForAdmin: response.data.totalForAdmin,
          totalAdminFee: response.data.totalAdminFee,
          totalNetAmount: response.data.totalNetAmount,
          totalOrders: response.data.totalOrders,
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      notification.error({
        message: "Error",
        description: "There was an error fetching the payment summary.",
        placement: "topRight",
      });
    }
  };

  const fetchVendorDetails = async (vendorId) => {
    try {
      setIsLoading(true);
      const response = await get(`/orders/vendor-payment-details/${vendorId}?token=${token}`);
      if (response.data.success) {
        console.log(response.data)
        setVendorDetails(response.data.orders);
        setIsModalVisible(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // notification.error({
      //   message: "Error",
      //   description: "There was an error fetching the vendor details.",
      //   placement: "topRight",
      // });
    }
  };

  const handleViewVendorDetails = (vendorId) => {
    fetchVendorDetails(vendorId);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setVendorDetails([]);
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Order Summary</Title>
      </TopContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Vendor ID</Th>
            <Th>Vendor Name</Th>
            <Th>Total Orders</Th>
            <Th>Total Payment</Th>
            <Th>Admin Commission</Th>
            <Th>Vendor Final Amount</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {summary.map((vendor) => (
            <Tr key={vendor.vendorId}>
              <Td>{vendor.vendorId}</Td>
              <Td>{vendor?.vendorName}</Td>
              <Td>{vendor.totalOrders}</Td>
              <Td>{vendor.totalPayment}</Td>
              <Td>{vendor.adminFee}</Td>
              <Td>{vendor.netAmount}</Td>
              <Td>
                <Button onClick={() => handleViewVendorDetails(vendor.vendorId)}>View</Button>
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td colSpan="2">Total for Admin</Td>
            <Td>{totals.totalOrders}</Td>
            <Td>{totals.totalForAdmin}</Td>
            <Td>{totals.totalAdminFee}</Td>
            <Td>{totals.totalNetAmount}</Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>

      <Modal
        title="Vendor Details"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={vendorDetails}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={`Order ID: ${item._id}`}
                description={
                  <div>
                    <p>Total Amount: {item.totalAmount}</p>
                    <p>Placed Date: {item.createdAt}</p>
                    <p>Items:</p>
                    <ul>
                      {item.items.map((product) => (
                        <li key={product.productId}>
                          {product?.product[0]?.title} - Quantity: {product.quantity} - Price: {product.price}
                        </li>
                      ))}
                    </ul>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
}

export default PaymentSummary;

import React, { useEffect, useRef, useState } from "react";
import {
  Main,
  Text,
  SubText,
  Div,
  Input,
  AccText,
  Signup,
  AccDiv,
  InputDiv,
} from "./SignInElements";
import Button from "../../components/Button/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { post } from "../../helpers/apiConfig";

// import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make a POST request to your login API
      const response = await post("/login", {
        email: username,
        password: password,
        role: "vendor",
      });
      // If login is successful, you might want to store the token in local storage or session storage
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("LOGGED_USER", JSON.stringify(response.data.user));
      // Navigate to the homepage and replace the current entry in the history stack
      navigate("/", { replace: true });

      // Clear input fields and error message
      setUsername("");
      setPassword("");
      setError("");

      // Redirect or perform any other action upon successful login
      // For example, you can use React Router to redirect to another page
    } catch (error) {

      if(error?.response){
        setError(error?.response?.data.message);

      }else{
        setError("Something went wrong, please try again later.");
      }
      // If login fails, set the error message
    }
  };

  return (
    <>
      <Main>
        <Text>Sign In</Text>
        <SubText>Let's build something great</SubText>
        <Text> {error && <p>{error}</p>} </Text>

        <InputDiv>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          ></Input>
        </InputDiv>
        <InputDiv>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Input>
        </InputDiv>

        <Button title="Login" onClick={handleSubmit} />

        <AccDiv>
          <AccText>Don't have an account?</AccText>
          <Signup
            onClick={() => {
              setUsername('')
              setPassword('')
              navigate("/signup");
            }}
          >
            Signup
          </Signup>
        </AccDiv>
      </Main>
    </>
  );
};

export default SignIn;

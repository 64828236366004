import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import NavBar from "../components/Navbar/navbar";
import Sidepanel from "../components/Sidepanel/sidepanel";
import { Main } from "../components/Sidepanel/sidepanelElement";
import Product from "./Product/Product";
import Dashboard from "./Dashboard/Dashboard";
import Category from "./Category/Category";
import Seller from "./Seller/Seller";
import Offer from "./Offer/Offer";
import Order from "./Order/Order";
import Customer from "./Customer/Customer";
import Subcategory from "./Subcategory/SubCategory";
import Commission from "./Commission/Commission";
import Banners from "./Banners/Banners";
import PaymentSummary from "./PaymentSummery/PaymentSummary";
import VendorPaymentDetails from "./PaymentSummery/VendorPaymentDetails";
import NewOrders from "./newOrders/NewOrders";

const LandingPage = () => {
  const loggedUser = JSON.parse(localStorage.getItem("LOGGED_USER"));

  console.log(loggedUser);
  
  const role = loggedUser?.role;

  return loggedUser ? (
    <div style={{ display: "flex", height: "100vh", overflow: "hidden" }} className="layout">
      <Sidepanel  />
      <div style={{ flex: 1, overflow: "auto", display: "flex", flexDirection: "column" }} className="mainWrapper">
      <div style={{ position: "relative", flex: "0 0 auto" }}>
     <NavBar data={loggedUser} />
     </div>
     <div style={{ flex: 1, overflow: "auto" }}>
        <Main>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/products" element={<Product isAdmin={role=="admin"}/>} />
            <Route path="/own-products" element={<Product type="admin" />} />
            <Route path="/orders" element={<Order isAdmin={role=="admin"}  />} />
            <Route path="/new-orders" element={<NewOrders  />} />
            <Route path="/own-products-orders" element={<Order type="admin" />} />
            <Route path="/customers" element={<Customer />} />
            <Route path="/offers" element={<Offer />} />
            <Route path="/category" element={<Category />} />
            <Route path="/sub-category" element={<Subcategory />} />
            <Route path="/seller" element={<Seller />} />
            <Route path="/commission" element={<Commission />} />
            <Route path="/banners" element={<Banners />} />
            <Route path="/payment-summary" element={<PaymentSummary />} />
            <Route path="/vendor-payment-details" element={<VendorPaymentDetails />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Main>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/signin" replace />
  );
};

export default LandingPage;
import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get, post, put, remove } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Button, Drawer, Form, Input, Select, DatePicker, message, Modal } from "antd";
import { validateMessages } from "../../helpers/validation";
import debounce from "lodash/debounce"; // Import lodash debounce

const { Option } = Select;

function Offer() {
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingOffer, setEditingOffer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const token = localStorage.getItem("token");

  const getAllOffers = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/admin/coupons?token=${token}`);
      if (response.data.success) {
        setOffers(response.data.data);
        setFilteredOffers(response.data.data);
      } else {
        // Handle the case where the response is not successful
        console.error("Failed to fetch offers:", response.data.message);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error("Failed to fetch offers:", e);
    }
  };

  useEffect(() => {
    getAllOffers();
  }, []);

  const handleEdit = (offer) => {
    setEditingOffer(offer);
    form.setFieldsValue(offer);
    setDrawerVisible(true);
  };


  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this promo code?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDelete(id),
    });
  };
  

  const handleDelete = async (id) => {
    try {
      const res = await remove(`/admin/coupon/${id}?token=${token}`);
      if (res.data.success) {
        setOffers(offers.filter((offer) => offer._id !== id));
        setFilteredOffers(filteredOffers.filter((offer) => offer._id !== id));
        message.success("Offer deleted successfully!");
      }
    } catch (error) {
      console.error("There was an error deleting the offer!", error);
      message.error("Failed to delete the offer.");
    }
  };

  const handleAddOffer = () => {
    setEditingOffer(null);
    form.resetFields();
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    try {
      if (editingOffer) {
        console.log(editingOffer?._id);
        // Update offer
        await put(`/admin/coupons?token=${token}`, {
          ...values,
          couponId: editingOffer._id,
        });
        setOffers(
          offers.map((offer) =>
            offer._id === editingOffer._id ? { ...offer, ...values } : offer
          )
        );
        setFilteredOffers(
          filteredOffers.map((offer) =>
            offer._id === editingOffer._id ? { ...offer, ...values } : offer
          )
        );
      } else {
        // Add new offer
        const response = await post(`/admin/coupons?token=${token}`, values);
        setOffers([...offers, response.data.coupon]);
        setFilteredOffers([...filteredOffers, response.data.coupon]);
      }
      setDrawerVisible(false);
      message.success(
        `Offer ${editingOffer ? "updated" : "added"} successfully!`
      );
    } catch (error) {
      console.error("There was an error saving the offer!", error);
      message.error("Failed to save the offer.");
    }
  };

  const handleSearch = debounce((e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = offers.filter(
      (offer) =>
        offer.category?.toLowerCase().includes(query) ||
        offer.subcategory?.toLowerCase().includes(query) ||
        offer.couponName?.toLowerCase().includes(query) ||
        offer.description?.toLowerCase().includes(query)
    );
    setFilteredOffers(filtered);
  }, 300); // Debounce for 300ms

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Offers</Title>

        <Input
          placeholder="Search offers"
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginRight: 10, width: 200 }}
        />

        <Button type="default" onClick={handleAddOffer} style={{ margin: 10 }}>
          Add Offer
        </Button>
      </TopContainer>
      <Table>
        <Thead>
          <tr>
            <Th>Id</Th>
            <Th>Coupon Name</Th>
            <Th>Description</Th>
            <Th>Coupon Percentage</Th>
            <Th>Max Discount</Th>
            <Th>Validity</Th>
            <Th>Category</Th>
            <Th>Subcategory</Th>
            <Th>Min Cart Value</Th>
            <Th>Action</Th>
          </tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {!isLoading &&
            filteredOffers.map((offer) => (
              <tr key={offer?._id}>
                <Td>{offer?._id}</Td>
                <Td>{offer?.couponName}</Td>
                <Td>{offer?.description}</Td>
                <Td>{offer?.couponPercentage}</Td>
                <Td>{offer?.maxDiscount}</Td>
                <Td>{offer?.validity?.toString()}</Td>{" "}
                {/* Adjusted for Date format */}
                <Td>{offer?.category}</Td>
                <Td>{offer?.subcategory}</Td>
                <Td>{offer?.minCartValue}</Td>
                <Td>
                  <EditOutlined
                    onClick={() => handleEdit(offer)}
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                  <DeleteOutlined
                    onClick={() => confirmDelete(offer._id)}
                    style={{ cursor: "pointer" }}
                  />
                </Td>
              </tr>
            ))}
        </Tbody>
      </Table>

      <Drawer
        title={editingOffer ? "Edit Offer" : "Add Offer"}
        width={720}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="couponName"
            label="Coupon Name"
            rules={[
              { required: true, message: "Please enter the coupon name" },
            ]}
          >
            <Input placeholder="Please enter the coupon name" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter the description" },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Please enter the description"
            />
          </Form.Item>
          <Form.Item
            name="couponPercentage"
            label="Coupon Percentage"
            rules={[
              { required: true, message: "Please enter the coupon percentage" },
            ]}
          >
            <Input
              type="number"
              placeholder="Please enter the coupon percentage"
            />
          </Form.Item>
          <Form.Item
            name="maxDiscount"
            label="Max Discount"
            rules={[
              { required: true, message: "Please enter the max discount" },
            ]}
          >
            <Input type="number" placeholder="Please enter the max discount" />
          </Form.Item>
          <Form.Item name="validity" label="Validity">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="category" label="Category">
            <Input placeholder="Please enter the category" />
          </Form.Item>
          <Form.Item name="subcategory" label="Subcategory">
            <Input placeholder="Please enter the subcategory" />
          </Form.Item>
          <Form.Item
            name="minCartValue"
            label="Min Cart Value"
            rules={[
              {
                required: true,
                message: "Please enter the minimum cart value",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Please enter the minimum cart value"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingOffer ? "Update" : "Add"} Offer
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default Offer;

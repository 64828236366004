import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Thead,
  Tbody,
  Td,
  Tr,
  Title,
  TopContainer,
} from "../Product/ProductElement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { bannerImageURL, get, post, put, remove, serverName } from "../../helpers/apiConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Button, Upload, Drawer, Form, Input, Switch, message, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";

function Banner() {
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingBanner, setEditingBanner] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const token = localStorage.getItem("token");

  const getAllBanners = async () => {
    try {
      setIsLoading(true);
      const response = await get(`/home/banner?token=${token}`);
      if (response.data.success) {
        setBanners(response.data.banners);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error("Error fetching banners:", e);
    }
  };

  useEffect(() => {
    getAllBanners();
  }, []);

  const handleEdit = (banner) => {
    setEditingBanner(banner);
    form.setFieldsValue(banner);
    setDrawerVisible(true);
  };


  const confirmDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this banner?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDelete(id),
    });
  };
  

  const handleDelete = async (id) => {
    try {
      const res = await remove(`/home/banner/${id}?token=${token}`);
      if (res.data.success) {
        setBanners(banners.filter((banner) => banner._id !== id));
      }
    } catch (error) {
      console.error("There was an error deleting the banner!", error);
    }
  };

  const handleAddBanner = () => {
    setEditingBanner(null);
    form.resetFields();
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      
      console.log('values', values['images'][0]);
      for (const key in values) {
        console.log(key);
        if (key === "images" && values[key]) {
          values[key].forEach((file) => {
            formData.append("image", file.originFileObj);
          });
        } else {
          formData.append(key, values[key]);
        }
      }

      let response;
      if (editingBanner) {
        response = await put(`/home/banner/${editingBanner._id}?token=${token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        response = await post(`/home/banner?token=${token}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (response.data.success) {
        message.success(`Banner ${editingBanner ? 'updated' : 'added'} successfully`);
        onClose();
        getAllBanners();
      } else {
        message.error(`Failed to ${editingBanner ? 'update' : 'add'} banner`);
      }
    } catch (error) {
      console.error(`Error ${editingBanner ? 'updating' : 'adding'} banner:`, error);
      message.error(`Failed to ${editingBanner ? 'update' : 'add'} banner`);
    }
  };

  const handleUploadChange = (info) => {
    if (info.file.status === "done") {
      const uploadedImageUrl = info.file.response.url;
      form.setFieldsValue({ images: uploadedImageUrl });
    }
  };

  const uploadProps = {
    name: "files",
    multiple: true,
    action: "", // Replace with your actual upload endpoint
    headers: {
      authorization: `Bearer ${token}`,
    },
    onChange: handleUploadChange,
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = banners.filter(
      (banner) =>
        banner.title.toLowerCase().includes(query) ||
        banner.description.toLowerCase().includes(query)
    );
    setBanners(filtered);
  };

  const handleVisibilityChange = async (banner, visible) => {
    try {
      const response = await put(`/home/banner/${banner._id}?token=${token}`, { visible });
      if (response.data.success) {
        message.success(`Banner visibility ${visible ? 'enabled' : 'disabled'}`);
        getAllBanners();
      } else {
        message.error(`Failed to change banner visibility`);
      }
    } catch (error) {
      console.error("Error updating banner visibility:", error);
      message.error("Failed to change banner visibility");
    }
  };

  return (
    <div style={{ backgroundColor: "#FFF", width: "100vw" }}>
      <TopContainer>
        <Title>Home Banners</Title>

        <Input
          placeholder="Search banners"
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginRight: 10, width: 200 }}
        />

        <Button
          type="default"
          onClick={handleAddBanner}
          style={{ margin: 10 }}
        >
          Add Banner
        </Button>
      </TopContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Id</Th>
            <Th>Title</Th>
            <Th>Description</Th>
            <Th>Images</Th>
            <Th>Visible</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && <LoadingSpinner />}
          {banners.map((banner) => (
            <Tr key={banner._id}>
              <Td>{banner._id}</Td>
              <Td>{banner.title}</Td>
              <Td>{banner.description}</Td>
              <Td>
               
                  <img  src={bannerImageURL +  banner?.image} alt={banner.title} width="50" style={{ marginRight: 5 }} />
              
              </Td>
              <Td>
                <Switch 
                  checked={banner.visible} 
                  onChange={(checked) => handleVisibilityChange(banner, checked)} 
                />
              </Td>
              <Td>
                <EditOutlined
                  onClick={() => handleEdit(banner)}
                  style={{ marginRight: 8, cursor: "pointer" }}
                />
                <DeleteOutlined
                  onClick={() => confirmDelete(banner._id)}
                  style={{ cursor: "pointer" }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Drawer
        title={editingBanner ? "Edit Banner" : "Add Banner"}
        width={720}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter the banner title" }]}
          >
            <Input placeholder="Please enter the banner title" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter the banner description" }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Please enter the banner description"
            />
          </Form.Item>
          <Form.Item
            name="images"
            label="Images"
            valuePropName="fileList"
            getValueFromEvent={(e) => Array.isArray(e) ? e : e && e.fileList}
            rules={[{ required: true, message: "Please upload images" }]}
          >
            <Upload {...uploadProps} listType="picture">
              <Button icon={<UploadOutlined />}>Upload Images</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingBanner ? "Update" : "Add"} Banner
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default Banner;
